import React from "react";
import Draggable from "react-draggable";
import '../../assets/stylesheets/scss/styles/_MapWrapper.scss';

class MapWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: 1,
            isDraggable: false,
        };
        this.nodeRef = React.createRef();
    }

    handleZoomIn = () => {
        if (this.state.scale < 1.5) {
            this.setState((prevState) => ({ scale: prevState.scale + 0.1, isDraggable: true }));
        }
    };

    handleZoomOut = () => {
        if (this.state.scale > 1.0) {
            this.setState((prevState) => ({ scale: prevState.scale - 0.1 }));
        }
    };

    handleReset = () => {
        this.setState({ scale: 1, isDraggable: false });
    };

    render() {
        const { source } = this.props;
        const { zoom } = this.props;
        const { scale, isDraggable } = this.state;
        const mapStyle = {
            transform: `scale(${scale})`,
        };

        return (
            <React.Fragment>
                <div className="MapWrapper">
                    <div className={`map-inner ${isDraggable && "zoomed"}`} style={mapStyle}>
                        {isDraggable ? (
                            <Draggable nodeRef={this.nodeRef}>
                                <div className="map-wrapper" ref={this.nodeRef}>
                                    {this.props.children}
                                    <img className="MapImage" src={source} alt="Map" />
                                </div>
                            </Draggable>
                        ) : (
                            <div className="map-wrapper" ref={this.nodeRef}>
                                {this.props.children}
                                <img className="MapImage" src={source} alt="Map" />
                            </div>
                        )}
                    </div>
                </div>
                <div className={ zoom + ' zoom-buttons' }>
                    <button onClick={this.handleZoomIn}>+</button>
                    <button onClick={this.handleZoomOut}>-</button>
                    <button className="reset" onClick={this.handleReset}>Reset</button>
                </div>
            </React.Fragment>
        );
    }
}

export default MapWrapper;
