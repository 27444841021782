import React from "react";
import MapWrapper from "../Widgets/MapWrapper";
import MapMarker from "../Widgets/MapMarker";
import HomeMap from "../../assets/img/maps/TEK_9999_interaktive_3D_Haus_v05_transparent.png";

import mapMarkersData from "../../Data/mapMarkersData";


class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Scene: null,
            Scene_id: 1,
        }

        this.openLink = this.openLink.bind(this);
    }

    openLink(Link) {
        // Öffne Link in gleichem Tab
        window.open(Link, '_blank');
    }

    render() {
        return (
            <React.Fragment>
                <MapWrapper source={HomeMap} zoom={'hidden'}>
                    {mapMarkersData.map((marker, index) => (
                        <MapMarker
                            key={index}
                            pos_x={marker.pos_x}
                            pos_y={marker.pos_y}
                            name={marker.name}
                            Scene_id={marker.Scene_id}
                            clickState={() => this.openLink(marker.Link)}
                        />
                    ))}
                </MapWrapper>
            </React.Fragment>
        );
    }
}

export default Overview;