import React from "react";

import '../../assets/stylesheets/scss/styles/_Sidebar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import heizung from '../../assets/img/scene/icons/heizung.png'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SidebarData from "../../Data/SidebarData";
import AccordionData from "../../Data/AccordionData";

class Sidebar extends React.Component {
    render() {
        const { Status, Scene_id, SidebarHandler } = this.props;

        const sidebarData = SidebarData.find(data => data.Scene_id === Scene_id);
        const accordionData = AccordionData.filter(data => data.Scene_id === Scene_id);

        return (
            <React.Fragment>
                <div className={'sidebar ' + Status }>
                    <div className="SidebarInner">
                        <div className="SidebarHeader">
                            <a className="SidebarHeader__Close" onClick={SidebarHandler}>
                                <FontAwesomeIcon
                                    className="SidebarHeader__Icon"
                                    icon={faCircleXmark}
                                />
                                Zurück zur Übersicht
                            </a>
                        </div>
                        <div className="SidebarContent">
                            <div className="SidebarContentHeader">
                                <div className="SceneIcon">
                                    <img src={heizung} alt="Heizungstechnik" />
                                </div>
                            </div>
                            <div className="SidebarContentBody">
                                {/* dynamic div with scene description */}
                                <div className="info">
                                    <h2>{sidebarData.Scene}</h2>
                                    <div className="devider"></div>
                                    <p>{sidebarData.Description}</p>
                                    <a className="readmore" href={sidebarData.ReadMore}>Mehr erfahren</a>
                                </div>

                                <div className="accordion">
                                    {accordionData.map((accordion, index) => (
                                        <Accordion key={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <p className="AccordionHeaderTitle">{accordion.Header}</p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    {accordion.Content}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Sidebar;