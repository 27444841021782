const AccordionData = [

    // Accordion Heizungstechnik

    { Scene_id: 1, Header: 'Accordion Heizungstechnik 1', Content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.' },
    { Scene_id: 1, Header: 'Accordion Heizungstechnik 2', Content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.' },

    // Accordion Elektroinstallation

    { Scene_id: 2, Header: 'Accordion Elektroinstallation', Content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.' },

];

export default AccordionData;