import '../../assets/stylesheets/scss/styles/_navigation.scss'
import HomeIcon from '../../assets/img/ui/house-sharp-solid.svg'

const Navigation = () => {
    return (
        <nav className="nav-header">
            <div className="nav-wrapper">
                <a className="home" href="#">
                    <img src={HomeIcon}/>
                </a>
            </div>
        </nav>
    );

};

export default Navigation;