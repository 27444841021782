import React from "react";
import '../../assets/stylesheets/scss/styles/_MapMarker.scss'

class MapMarker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { pos_x, pos_y, ref, Scene_id, name, clickState } =this.props;
        return (
            <React.Fragment>
               <div
                   className={"MapMarker Scene_" + Scene_id}
                    style={{
                        left: pos_x,
                        top: pos_y,
                    }}>
                <a>
                    <span className="MapMarkerIcon"></span>
                    <div className="MapMarkerDescription" onClick={clickState}>
                        <p>{name}</p>
                    </div>
                </a>
               </div>
            </React.Fragment>
        )
    }
}

export default MapMarker
