import React from "react";
import "../assets/stylesheets/scss/layout.scss";

import MenuBar from "../Components/navigations/menubar";


const Layout = (props) => {
    return(
        <React.Fragment>

            <main className="main-content">
                {props.children}
            </main>

        </React.Fragment>
    );
};

export default Layout;